<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>意见与建议</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="content">
        <el-image style="width: 60px; height: 60px" :src="img" fit="cover"></el-image>

        <div class="tip">已提交成功！</div>

        <div class="btn" @click="jump">返回首页</div>
      </div>
    </div>
  </transition>
</template>

<script>
import img from "../../assets/img/opinion/icon1.png";
export default {
  data() {
    return {
      img: img,
    };
  },
  methods: {
    jump() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding-top: 80px;
  margin-top: 30px;
  background: #f9fbfc;
  height: 390px;
}
.tip {
  font-size: 20px;
  margin: 30px 0;
}

.btn {
  cursor: pointer;
  margin: 0 auto;
  color: #fff;
  width: 220px;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  background: #db3b3d;
}
</style>
